import React, { Component } from 'react';
import { connect } from 'react-redux';
import MASpinner from '../../components/common/MASpinner';
import Card from 'react-bootstrap/Card';
import { Col, Row } from 'react-bootstrap';
import { siteSettingSingleUpdate, siteSettingsListFetch, siteSettingsListUnload } from '../../lib/redux/actions/siteSettings';
import EditableSettingTR from '../../components/forms/SiteSettings/EditableSettingTR';
//import { requests } from '../../lib/helper/agent';
//import * as API_ROUTES from '../../lib/routing/api';
const mapStateToProps = (state) => ({
  siteSettings: state.siteSettings.siteSettings ?? [],
  isFetchingSiteSettings: state.siteSettings.isFetchingSiteSettings,
});

const mapDispatchToProps = {
  siteSettingsListFetch,
  siteSettingsListUnload,
  siteSettingSingleUpdate,
};

class SiteSettingsContainer extends Component {
  componentDidMount() {
    this.props.siteSettingsListFetch(null, {pagination: false});
  }

  componentWillUnmount() {
    this.props.siteSettingsListUnload();
  }

  render() {
    const { isFetchingSiteSettings, siteSettings } = this.props;
    if (isFetchingSiteSettings) {
      return <MASpinner />;
    }

    //    const chase = (n) => {
    //      try {
    //        requests.post(`${API_ROUTES.SIMULATE_CHASE_INVOICE + '?chase=' + n}`);
    //      } catch (error) {}
    //    };
    return (
      <div>
        <Row>
          <Col>
            <Card className="mb-4">
              <Card.Header>Portal Settings</Card.Header>
              <Card.Body>
                <Row>
                  {/* Left Column - START */}
                  <Col sm={12} md={12} lg={8}>
                    <table className={'table table-sm table-striped'}>
                      <tbody>
                        <tr>
                          <td>Setting Name</td>
                          <td>Value</td>
                          <td width={'80'} />
                        </tr>
                        {siteSettings.map((setting) => (
                          <EditableSettingTR setting={setting} key={setting.id} />
                        ))}
                      </tbody>
                    </table>
                  </Col>
                  {/*<Col sm={12} md={12} lg={4}>*/}
                  {/*  <button type="button" className="btn btn-primary mx-1" onClick={() => chase(1)}>*/}
                  {/*    Simulate Chase 1*/}
                  {/*  </button>*/}
                  {/*  <button type="button" className="btn btn-primary mx-1" onClick={() => chase(2)}>*/}
                  {/*    Simulate Chase 2*/}
                  {/*  </button>*/}
                  {/*  <button type="button" className="btn btn-primary mx-1" onClick={() => chase(3)}>*/}
                  {/*    Simulate Chase 3*/}
                  {/*  </button>*/}
                  {/*</Col>*/}
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SiteSettingsContainer);
//
//SiteSettingsContainer.propTypes = {
//  apiRoute: PropTypes.string.isRequired,
//};
